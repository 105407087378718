import React from "react"
import PropTypes from "prop-types"
import language from "@language"
import { graphql } from "gatsby"
import gql from "graphql-tag"
import withPreview from "@components/withPreview"

import Layout from "@components/layout"
import SEO from "@components/seo"
import { filterPageForTemplate } from "@templates/utilities/templateUtils"

import FadeInSection from "@components/common/FadeInSection"
import BreadcrumbBand from "@components/common/BreadcrumbBand"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import ArticleMarkdownContent from "@components/common/ArticleMarkdownContent"

import ServicesHero from "./components/ServicesHero"

import NewsBannerCard from "@templates/news/components/NewsBannerCard"
import LocationGuideItem from "./components/LocationGuideItem"

import { URLify } from "@utilities/copyUtils"

import "./style.scss"

const ServicesPage = props => {
  const page =
    !!props.preview && !!props.preview.page
      ? props.preview.page.preview.node
      : filterPageForTemplate(
        props.data.allWpPage.edges,
        props.pageContext.wordpressUri
      )

  const locationsWithGuides = page.acfPatientsPage.guidesSection.locations.filter(
    l =>
      l.acf.publishFinal &&
      (l.acf.guide || l.acf.estimatedChargesGuide || l.acf.chargeMasterGuide)
  )

  const sectionItems = [
    {
      id: URLify(page.acfPatientsPage.newsSection.title),
      label: page.acfPatientsPage.newsSection.title,
    },
    {
      id: URLify(page.acfPatientsPage.guidesSection.title),
      label: page.acfPatientsPage.guidesSection.title,
    },
    {
      id: URLify(page.acfPatientsPage.securitySection.title),
      label: page.acfPatientsPage.securitySection.title,
    }
    // {
    //   id: URLify(page.acfPatientsPage.parkingSection.title),
    //   label: page.acfPatientsPage.parkingSection.title,
    // },
  ]

  return (
    <Layout isHomepage={false}>
      <SEO title={page.acfPageMeta.metaTitle} />
      <div className="page-fade-in">
        <BreadcrumbBand
          pageTitle={page.acfPageMeta.metaTitle}
          systemTextSize="sm"
        />
        <div className="page-wrap">
          <div className="row">
            <div className="services__left offset-lg-1 col col-lg-8">
              <ServicesHero
                overline={page.acfPatientsPage.pHero.overline}
                title={page.acfPatientsPage.pHero.title}
              />
              <FadeInSection>
                <div className="services__section">
                  <div
                    className="anchor"
                    id={URLify(page.acfPatientsPage.newsSection.title)}
                  ></div>
                  <div className="">
                    <HeadingText
                      className="mb-40px"
                      color="dark-gray"
                      size="md"
                      text={page.acfPatientsPage.newsSection.title}
                    />
                    {page.acfPatientsPage.newsSection.news
                      .filter(news => news.acf.publishFinal)
                      .map((news, index) => {
                        return (
                          <NewsBannerCard
                            imageColSize={6}
                            key={`services-NewsBannerCard--${index}`}
                            language={props.language}
                            news={news}
                            withTopBorder={true}
                          />
                        )
                      })}
                  </div>
                </div>
              </FadeInSection>
              {locationsWithGuides && locationsWithGuides.length && (
                <FadeInSection>
                  <div className="services__section">
                    <div
                      className="anchor"
                      id={URLify(page.acfPatientsPage.guidesSection.title)}
                    ></div>
                    <div className="">
                      <HeadingText
                        className="mb-40px"
                        color="dark-gray"
                        size="md"
                        text={page.acfPatientsPage.guidesSection.title}
                      />
                      {locationsWithGuides.map((location, index) => {
                        return (
                          <LocationGuideItem
                            key={`services-LocationGuideItem--${index}`}
                            location={location}
                          />
                        )
                      })}
                    </div>
                  </div>
                </FadeInSection>
              )}

              <FadeInSection>
                <div className="services__section">
                  <div
                    className="anchor"
                    id={URLify(page.acfPatientsPage.securitySection.title)}
                  ></div>
                  <div className="">
                    <div className="">
                      <HeadingText
                        className="mb-40px"
                        color="dark-gray"
                        size="md"
                        text={page.acfPatientsPage.securitySection.title}
                      />
                      <div className="">
                        <ArticleMarkdownContent
                          className=""
                          content={page.acfPatientsPage.securitySection.content}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </FadeInSection>
              {/* <FadeInSection>
                <div className="services__section">
                  <div
                    className="anchor"
                    id={URLify(page.acfPatientsPage.parkingSection.title)}
                  ></div>
                  <HeadingText
                    className="mb-40px"
                    color="dark-gray"
                    size="md"
                    text={page.acfPatientsPage.parkingSection.title}
                  />
                  <ArticleMarkdownContent
                    className=""
                    content={page.acfPatientsPage.parkingSection.content}
                  />
                </div>
              </FadeInSection> */}
            </div>
            <div className="services__right d-none d-lg-block col-3">
              <div className="services__card">
                <BodyText
                  className="mb-20px"
                  size="sm"
                  color="dark-gray"
                  text={`${language[props.language].jump_to_a_section}:`}
                />
                {sectionItems.map(item => {
                  return (
                    <a href={`#${item.id}`}>
                      <BodyText
                        className="mb-20px text-link"
                        size="sm"
                        color="primary"
                        text={item.label}
                      />
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

ServicesPage.propTypes = {
  language: PropTypes.string,
}

ServicesPage.defaultProps = {
  language: "es",
}

export const query = graphql`
  query PatientsPageQuery {
    allWpPage(filter: { acfPageMeta: { pageTemplate: { eq: "services" } } }) {
      edges {
        node {
          id
          uri
          acfPageMeta {
            metaImage {
              altText
              sourceUrl
            }
            metaDescription
            metaTitle
          }
          acfPatientsPage {
            newsSection {
              news {
                ... on WpNewsPost {
                  id
                  title
                  acf {
                    category {
                      name
                      acfCategory {
                        color
                      }
                    }
                    metaDescription
                    metaImage {
                      altText
                      sourceUrl
                    }
                    displayDate
                    publishFinal
                  }
                  uri
                }
              }
              title
            }
            pHero {
              title
              overline
            }
            parkingSection {
              content
              title
            }
            securitySection {
              content
              title
            }
            guidesSection {
              locations {
                ... on WpLocation {
                  id
                  acf {
                    guide {
                      altText
                      mediaItemUrl
                    }
                    estimatedChargesGuide {
                      altText
                      mediaItemUrl
                    }
                    chargeMasterGuide {
                      altText
                      mediaItemUrl
                    }
                    telephone
                    otherTelephones {
                      telephoneLabel
                      telephoneNumber
                    }
                    publishFinal
                  }
                  title
                }
              }
              title
            }
          }
        }
      }
    }
  }
`
const PREVIEW_QUERY = gql`
  query PreviewPatientsPageQuery {
    allWpPage(filter: { acfPageMeta: { pageTemplate: { eq: "services" } } }) {
      edges {
        node {
          id
          uri
          acfPageMeta {
            metaImage {
              altText
              sourceUrl
            }
            metaDescription
            metaTitle
          }
          acfPatientsPage {
            newsSection {
              news {
                ... on NewsPost {
                  id
                  title
                  acf {
                    category {
                      name
                      acfCategory {
                        color
                      }
                    }
                    metaDescription
                    metaImage {
                      altText
                      sourceUrl
                    }
                    displayDate
                    publishFinal
                  }
                  uri
                }
              }
              title
            }
            pHero {
              title
              overline
            }
            parkingSection {
              content
              title
            }
            securitySection {
              content
              title
            }
            guidesSection {
              locations {
                ... on Location {
                  id
                  acf {
                    guide {
                      altText
                      mediaItemUrl
                    }
                    estimatedChargesGuide {
                      altText
                      mediaItemUrl
                    }
                    chargeMasterGuide {
                      altText
                      mediaItemUrl
                    }
                    telephone
                    otherTelephones {
                      telephoneLabel
                      telephoneNumber
                    }
                    publishFinal
                  }
                  title
                }
              }
              title
            }
          }
        }
      }
    }
  }
`

export default withPreview({ preview: PREVIEW_QUERY })(ServicesPage)
