import React from "react"
import PropTypes from "prop-types"
import HeadingText from "@components/common/HeadingText"

import "./style.scss"

const ServicesHero = props => {
  return (
    <div className="services-hero">
      <div className="services-hero__left">
        <HeadingText
          className="mb-10px"
          color="dark-gray"
          size="md"
          text={props.overline}
        />
        <HeadingText color="primary" size="xl2" text={props.title} />
      </div>
    </div>
  )
}

ServicesHero.propTypes = {
  overline: PropTypes.string,
  title: PropTypes.string,
  sectionItems: PropTypes.array,
  language: PropTypes.string,
}
ServicesHero.defaultProps = {
  language: "es",
}

export default ServicesHero
