import React from "react"
import PropTypes from "prop-types"

import language from "@language"
import { ReactComponent as DocumentIcon } from "@images/icons/pdf.svg"

import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import BrandButton from "@components/common/BrandButton"
import UniversalLink from "@components/common/UniversalLink"

import "./style.scss"

const filterTelephoneNumber = telephoneNumber => {
  if (!telephoneNumber) return ""
  return telephoneNumber.replace(/[^0-9]/g, "")
}

const LocationGuideItem = props => {
  return (
    <div className="location-guide-item">
      <div className="location-guide-item__title mb-30px">
        <HeadingText
          className="location-guide-item__title--site"
          text={"Doctors' Center Hospital"}
          color="primary-dark"
          size="md"
        />
        <HeadingText text={props.location.title} color="primary" size="md" />
      </div>
      {
        props.location.acf.telephone && <div className="location-guide-item__phone mb-20px">
          <BodyText
            className="location-guide-item__phone--label"
            element="p"
            color="dark-gray"
            size="md"
            text={`${language[props.language].main_telephone}: `}
          />
          <UniversalLink
            href={`tel:${filterTelephoneNumber(
              props.location.acf.telephone
            )}`}
          >
            <BodyText
              element="p"
              color="primary"
              size="md"
              text={props.location.acf.telephone}
            />
          </UniversalLink>
        </div>
      }



      {!!props.location.acf.otherTelephones &&
        !!props.location.acf.otherTelephones.length &&
        props.location.acf.otherTelephones.map(otherTelephone => {
          return (
            <div className="location-guide-item__phone mb-20px">
              <BodyText
                className="location-guide-item__phone--label"
                element="p"
                color="dark-gray"
                size="md"
                text={`${otherTelephone.telephoneLabel}:`}
              />

              <BodyText
                element="p"
                color="dark-gray"
                size="md"
                text={otherTelephone.telephoneNumber}
              />
            </div>
          )
        })}
      {
      <div className="location-guide-item__row">
        
        {/*props.location.acf.guide && (
          <BrandButton
            className="mb-60px mr-20px"
            color="primary"
            href={props.location.acf.guide.mediaItemUrl}
            icon={<DocumentIcon style={{ height: "24px" }} />}
            text={language[props.language].hospital_guide}
          />
        )*/}
        {props.location.acf.estimatedChargesGuide && (
          <BrandButton
            className="mb-60px mr-20px text-color--primary"
            btnStyle="shadow"
            color="transparent"
            href={props.location.acf.estimatedChargesGuide.mediaItemUrl}
            icon={
              <DocumentIcon
                className="svg--primary"
                style={{ height: "24px" }}
              />
            }
            text={language[props.language].estimated_charges_guide}
          />
        )}
{/*{props.location.acf.chargeMasterGuide && (
          <BrandButton
            className="mb-60px text-color--primary"
            btnStyle="shadow"
            color="transparent"
            href={props.location.acf.chargeMasterGuide.mediaItemUrl}
            icon={
              <DocumentIcon
                className="svg--primary"
                style={{ height: "24px" }}
              />
            }
            text={language[props.language].charge_master_guide}
          />
        )}*/}
      </div>
      }
    </div>
  )
}

LocationGuideItem.propTypes = {
  location: PropTypes.shape({
    title: PropTypes.string,
    acf: PropTypes.shape({
      publishFinal: PropTypes.bool,
      guide: PropTypes.shape({
        altText: PropTypes.string,
        mediaItemUrl: PropTypes.string,
      }),
      estimatedChargesGuide: PropTypes.shape({
        altText: PropTypes.string,
        mediaItemUrl: PropTypes.string,
      }),
      chargeMasterGuide: PropTypes.shape({
        altText: PropTypes.string,
        mediaItemUrl: PropTypes.string,
      }),
      otherTelephones: PropTypes.array,
    }),
  }),
  language: PropTypes.string,
}
LocationGuideItem.defaultProps = {
  language: "es",
}

export default LocationGuideItem
